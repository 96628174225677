import React, { useEffect, useState } from "react";
import styles from "./translate.module.scss";
import DatePicker from "react-datepicker"
import stylesGeneric from "../../styles/generics.module.scss";
import { getCookie } from "../../methods/cookie";
import {useNavigate, useParams} from "react-router-dom";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {AppConfigs} from "../../interfaces/AppConfig";
import {fetchWrapper, postDesign} from "../../methods/connector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGear, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {SaveButton} from "../../components/SaveButton/SaveButton";
import {Background} from "../../components/background/Background";
import {canTranslate, getConstants} from "../../methods/getEvents";
import {logout, openTab} from "../../methods/helpers";
import "react-datepicker/dist/react-datepicker.css";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";

const _ = require('lodash');

// list all stages for the given config, with a button to open and edit them
export const LanguageTranslator = () => {
  const routeParams = useParams();
  const designTag = routeParams.designTag;
  const language = routeParams.language;

  const navigate = useNavigate();
  const jwt = getCookie('jwt');

  const [design, setDesign] = useState<AppConfigs>();
  const [add, setAdd] = useState<boolean>(false);
  const [addString, setAddString] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [language1, setLanguage1] = useState<string>(language ?? "");
  const [language2, setLanguage2] = useState<string>("");

  const d1 = design?.language?.[language1] ?? {};
  const d2 = design?.language?.[language2] ?? {};

  const getAllKeys = () => {
    return [
      ...Object.keys(d1),
      ...Object.keys(d2),
    ].filter((v, i, a) => a.indexOf(v) === i);
  }

  const languages = Object.keys(design?.language ?? {});

  const addEntry = async () => {
    if (!design || !designTag || !language1) {
      return;
    }
    const d = _.cloneDeep(design)

    if (d?.language?.[language1]?.[addString]) {
      return;
    }

    if (!(d?.language)){
      d.language = {};
    }
    if (!(d.language[language1])){
      d.language[language1] = {};
    }
    d.language[language1][addString] = "";
    setDesign(d);
  }

  const deleteEntry = async (key: string) => {
    if (!design || !designTag || !language) {
      return;
    }
    const d = _.cloneDeep(design)
    delete d?.language?.[language]?.[key];
    setDesign(d);
  }

  const setValue = (key: string, value: string, lan: string) => {
    if (!design || !designTag || !language) {
      return;
    }

    const d = _.cloneDeep(design)
    if (d?.language?.[lan] === undefined) {
      return;
    }
    d.language[lan][key] = value;
    setDesign(d);
  }

  const setValues = (data: [string, string][], lan: string) => {
    if (!design || !designTag || !language) {
      return;
    }

    const d = _.cloneDeep(design)
    if (d?.language?.[lan] === undefined) {
      return;
    }
    data.forEach((entry) => {
      if (entry[0] === "") {
        return;
      }
      d.language[lan][entry[0]] = entry[1];
    })
    setDesign(d);
  }

  const translateOne = async (key: string, l1: string, l2: string) => {
    if (l1 === "" || l2 === "" || l1 === l2) {
      return;
    }
    if (!canTranslate(l1) || !canTranslate(l2)) {
      return;
    }

    const input = (design?.language?.[l1]?.[key]) ?? "";
    if (input === "") {
      return;
    }

    let url = `${process.env.REACT_APP_URL}/translate`;
    const res = await fetchWrapper(url, jwt, 'post', JSON.stringify({
      inputs: [input],
      language_from: l1,
      language_to: l2
    }));
    const data: [{[text: string]: string}] = await res.json().then((r) => r.translations);
    setValues([[key, data[0].text]], l2);

    return data;
  }

  const translateAll = async (l1: string, l2: string) => {
    if (l1 === "" || l2 === "" || l1 === l2) {
      return;
    }
    if (!canTranslate(l1) || !canTranslate(l2)) {
      return;
    }

    // const input = (design?.language?.[l1]?.[key]) ?? "";
    const inputs = Object.entries(design?.language?.[l1] ?? {});
    if (inputs.length === 0) {
      return;
    }

    let url = `${process.env.REACT_APP_URL}/translate`;
    const res = await fetchWrapper(url, jwt, 'post', JSON.stringify({
      inputs: inputs.map((i) => i[1]),
      language_from: l1,
      language_to: l2
    }));
    const data: [{[text: string]: string}] = await res.json().then((r) => r.translations);
    const dataToApply: [string, string][] = data.map((val, i) => [inputs[i]?.[0] ?? "", val.text]);
    setValues(dataToApply, l2);

    return data;
  }



  const postConfig = async () => {
    if (!design) {
      return;
    }

    setLoading(true);
    const data = await postDesign(design, jwt)
    setLoading(false);
    if (data.status !== 200) {
      return;
    }
  }

  const getDesign = async() => {
    let url = `${process.env.REACT_APP_URL}/designByTag/${designTag}`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'get');
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r: AppConfigs = await data.json();
    setDesign(r);
  }

  useEffect(() => {
    getDesign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className={stylesGeneric["page"]}>
    <Background/>
    {/*<HomeButtonRow/>*/}
    <div className={stylesGeneric["settings-button-row"]}>
      <div className={stylesGeneric["settings-button"]}>
        <HomeButton/>
      </div>
      <div className={stylesGeneric["settings-button"]}>
        <SaveButton onClick={postConfig}/>
      </div>
      <div className={stylesGeneric["settings-button"]}>
        <button className={stylesGeneric['generic-button-wrapper']} onClick={() => {
          setAdd(!add);
        }}>
          <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faPlus}/>
        </button>
      </div>
    </div>
    <div className={stylesGeneric["content-wrapper"]}>
      <div className={stylesGeneric["page-wrapper"]}>
        <table className={styles['table']}>
          <tbody className={styles['tbody']}>
          <tr className={styles['tr-head']}>
            <td className={`${styles['td-tag']} ${styles['td-border']}`}/>
            <td className={styles['td-head']}>
              <Dropdown currentVal={language1 ?? ""} valueList={languages} onChange={(l: string) => {
                setLanguage1(l)
              }}/>
            </td>
              <td className={`${styles['td-button']} ${styles['td-border']}`}>
              <input className={styles["button"]} value={"->"} onClick={() => translateAll(language1, language2)} type="button"/>
            </td>

            <td className={styles['tr-button']}>
              <input className={styles["button"]} value={"<-"} onClick={() => translateAll(language2, language1)} type="button"/>
            </td>
            <td className={styles['td-head']}>
              <Dropdown currentVal={language2 ?? ""} valueList={languages} onChange={(l: string) => {
                setLanguage2(l)
              }}/>
            </td>
          </tr>
          {/*</thead>*/}
          {/*<tbody className={styles['tbody']}>*/}
          {
            getAllKeys().sort().map((key) => {
              return <tr className={styles['tr']} key={key}>
                <td className={`${styles['td-tag']} ${styles['td-border']}`}>
                  {key}
                </td>
                <td className={styles['td-value']}>
                  <input
                    className={styles["field"]}
                    value={d1[key] ?? ""}
                    onChange={(e) => {
                      setValue(key, e.target.value, language1)
                    }}
                  />
                </td>

                <td className={`${styles['td-button']} ${styles['td-border']}`}>
                  <input className={styles["button"]} value={"->"} onClick={() => translateOne(key, language1, language2)} type="button"/>
                </td>

                <td className={styles['td-button']}>
                  <input className={styles["button"]} value={"<-"} onClick={() => translateOne(key, language2, language1)} type="button"/>
                </td>

                <td className={styles['td-value']}>
                  <input
                    className={styles["field"]}
                    value={d2[key] ?? ""}
                    onChange={(e) => {
                      setValue(key, e.target.value, language2)
                    }}
                  />
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    </div>
    {
      add
        ?
        <div className={styles["add-wrapper"]}>
          <input value={addString} placeholder={'Enter new key...'} onChange={(e) => {setAddString(e.target.value)}} className={styles["add-input"]}/>
          <div className={styles["add-buttons"]}>
            <input className={styles["add-button"]} value={"OK"} onClick={addEntry} type="button"/>
            <input className={styles["add-button"]} value={"Close"} onClick={() => {setAdd(false); setAddString('')}} type="button"/>
          </div>
        </div>
        : null
    }
    <LoadingOverlay loading={loading}/>
  </div>
}
