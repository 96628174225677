import {AppConfig, AppConfigs, AppDesign, AppElement} from "../interfaces/AppConfig";
import {config} from "@fortawesome/fontawesome-svg-core";
import {getKeyVal} from "./helpers";

const _ = require("lodash");

const mergeElement = (elementIn: AppElement, elementTemplate: AppElement): AppElement => {
  const handleFunction = {...elementIn.handleFunction ?? {}, ...elementTemplate.handleFunction ?? {}};
  const condition = {...elementIn.condition ?? {}, ...elementTemplate.condition ?? {}};
  const background = {...elementIn.background ?? {}, ...elementTemplate.background ?? {}};
  const innerSize = {...elementIn.innerSize ?? {}, ...elementTemplate.innerSize ?? {}};

  const elementOut = {...elementTemplate};
  elementOut.css = {...elementTemplate.css ?? {}};

  for (let [key, val] of Object.entries(elementIn)) {
    if (typeof val !== 'string' || val === "") {
      continue;
    }
    elementOut[key] = val;
  }

  elementOut.css = {...elementTemplate.css} ?? {};
  for (const [key, val] of Object.entries(elementIn.css ?? {})) {
    if (val !== undefined && val !== "") {
      elementOut.css[key] = val;
    }
  }

  // @ts-ignore
  elementOut.handleFunction = Object.keys(handleFunction).length > 0 ? handleFunction : undefined;
  // @ts-ignore
  elementOut.condition = Object.keys(condition).length > 0 ? condition : undefined;
  // @ts-ignore
  elementOut.background = Object.keys(background).length > 0 ? background : undefined;
  // @ts-ignore
  elementOut.innerSize = Object.keys(innerSize).length > 0 ? innerSize : undefined;
  elementOut.deletedKeys = elementIn.deletedKeys ?? undefined;
  elementOut.templateName = elementIn.templateName;
  elementOut.useTemplate = elementIn.useTemplate;


  for (const deletedKey of elementOut.deletedKeys ?? []) {
    const keySplit = deletedKey.split('.');
    let elemIt = elementOut;
    if (keySplit.length > 1) {
      for (let i = 0; i < keySplit.length - 1; i++) {
        elemIt = elemIt[keySplit[i]];
      }
    }
    delete elemIt[keySplit[keySplit.length - 1]];
  }

  return elementOut;

}

const mergeElements = (elementsIn: Record<string, AppElement>, elementsTemplate: Record<string, AppElement>)
  : Record<string, AppElement> => {
  const mergedElements: Record<string, AppElement> = {};

  const keysIn = Object.keys(elementsIn);
  const keysTemplate = Object.keys(elementsTemplate);
  let combinedKeys: string[] = [];

  for (const key of keysTemplate) {
    if (!(keysIn.includes(key))) {
      mergedElements[key] = elementsTemplate[key];
    } else {
      combinedKeys.push(key);
    }
  }

  for (const key of keysIn) {
    if (!(keysTemplate.includes(key))) {
      mergedElements[key] = elementsIn[key];
    } else {
      combinedKeys.push(key);
    }
  }


  combinedKeys = combinedKeys.filter((v, i, a) => a.indexOf(v) === i);


  for (const key of combinedKeys) {
    const elementIn = elementsIn[key];
    const elementTemplate = elementsTemplate[key];
    mergedElements[key] = mergeElement(elementIn, elementTemplate);
  }


  return mergedElements;
}


/**
 * merge a page with its templates (recursively)
 * @param pages the current page and its templates.
 * merge is from left to right, with the rightmost overwriting the one on the left
 * e.g. [template 0, template 1, current Page]
 */
const mergeTemplates = (pages: AppConfig[]): AppConfig => {
  if (pages.length === 1)  {return _.cloneDeep(pages[0])}
  const returnPage: AppConfig = _.cloneDeep(pages[0]);
  for (let i = 1; i < pages.length; i++) {
    const page: AppConfig = _.cloneDeep(pages[i]);
    const mergedElements = mergeElements(page.design.elements, returnPage.design.elements);
    for (const [key, value] of Object.entries(page.design)) {
      if (typeof value === 'object' && key !== 'elements' && value !== undefined && value !== null) {
        for (const [subKey, subValue] of Object.entries(value)) {
          if (subValue !== undefined && subValue !== null && subValue !== "" && subValue !== 0) {
            if (returnPage.design[key] === undefined) {
              returnPage.design[key] = {};
            }
            returnPage.design[key][subKey] = subValue
          }
        }

      } else if (typeof value === 'string' && value !== "") {
        returnPage.design[key] = value;
      } else if (typeof value === "boolean" || typeof value === "number") {
        returnPage.design[key] = value;

      }
    }

    for (const [key, value] of Object.entries(page.settings)) {
      if (typeof value === 'object' && !Array.isArray(value) && value !== undefined && value !== null) {
        for (const [subKey, subValue] of Object.entries(value)) {
          if (subValue !== undefined && subValue !== null && subValue !== "" && subValue !== 0) {
            if (returnPage.settings[key] === undefined || returnPage.settings[key] === null) {
              returnPage.settings[key] = {};
            }
            returnPage.settings[key][subKey] = subValue
          }
        }

      } else if (typeof value === 'string' && value !== "") {
        returnPage.settings[key] = value;
      }
    }


    returnPage.design.elements = mergedElements;
    // returnPage.design.backgroundBounds = {...page.design.backgroundBounds, ...returnPage.design.backgroundBounds};
    // returnPage.design.backgroundSize = {...page.design.backgroundSize, ...returnPage.design.backgroundSize};
    // returnPage.design.orientation = {...page.design.orientation, ...returnPage.design.orientation};
    // returnPage.design.scroll = {...page.design.scroll, ...returnPage.design.scroll};

    returnPage.settings.copyParameters = [...page.settings.copyParameters ?? [], ...returnPage.settings.copyParameters ?? []];
    returnPage.settings.setParameters = [...page.settings.setParameters ?? [], ...returnPage.settings.setParameters ?? []];
    returnPage.settings.templateName = pages[pages.length - 1].settings.templateName;
    returnPage.settings.useTemplate = pages[pages.length - 1].settings.useTemplate;
    returnPage.settings.useDesign = pages[pages.length - 1].settings.useDesign;
  }
  return returnPage;
}

export const registerTemplates = (config: AppConfigs): [Record<string, AppConfig>, boolean] => {
  const templates: Record<string, AppConfig> = {};
  let pageNames = new Set(Object.keys(config.configs));
  let valid = true;

  while (valid) {
    let found = false;
    const pageNamesArr = Array.from(pageNames.keys());
    for (let pageName of pageNamesArr) {

      const page = config.configs[pageName];
      if (!page.settings.templateName) {
        pageNames.delete(pageName);
        continue;
      }

      if (page.settings.useTemplate !== undefined && page.settings.useTemplate !== "") {
        if (Object.keys(templates).includes(page.settings.useTemplate)) {
          if (templates[page.settings.templateName]) {
            valid = false;
            break;
          }
          templates[page.settings.templateName] = mergeTemplates([templates[page.settings.useTemplate], page]);
          found = true;
          pageNames.delete(pageName);
          break;
        } else {
          continue;
        }
      }

      if (templates[page.settings.templateName]) {
        valid = false;
        break;
      }
      templates[page.settings.templateName] = page;
      found = true;
      pageNames.delete(pageName);
      break;
    }
    if (!found) {
      if (Object.keys(pageNames).length > 0) {
        valid = false;
      }
      break;
    }
  }
  return [templates, valid];
}

export const registerElements = (config: AppConfigs): [Record<string, AppElement>, boolean] => {
  const elements: Record<string, AppElement> = {};

  const elementsToProcess: Record<string, AppElement> = {};
  let valid = true;
  for (const page of Object.values(config.configs)) {
    for (const [name, element] of Object.entries(page.design.elements)) {
      if (!(element.templateName)) {
        continue;
      }
      if (elementsToProcess[element.templateName]) {
        valid = false;
        break;
      }
      elementsToProcess[element.templateName] = _.cloneDeep(element);
    }
  }

  while (valid) {
    let found = false;
    for (const [elementName, element] of Object.entries(elementsToProcess)) {
      if (!(element.useTemplate)) {
        found = true
        elements[elementName] = element;
        delete elementsToProcess[elementName];
        continue;
      }
      if (!(Object.keys(elements).includes(element.useTemplate))) {
        continue;
      }

      found = true;
      elements[elementName] = mergeElement(element, elements[element.useTemplate]);
      delete elementsToProcess[elementName];
    }
    if (!found) {
      if (Object.keys(elementsToProcess).length > 0) {
        valid = false;
      }
      break;
    }
  }
  return [elements, valid];
}

export const fillConfig = (config: AppConfig, templates: Record<string, AppConfig>, templatesElements: Record<string, AppElement>): AppConfig => {
  let configFilled: AppConfig = _.cloneDeep(config);
  const template = templates[configFilled.settings.useTemplate ?? ''];
  if (template !== undefined) {
    configFilled = mergeTemplates([template, configFilled]);
  }

  for (const [key, element] of Object.entries(configFilled.design.elements)) {
    const useTemplate = element.useTemplate;
    if (!useTemplate) {
      continue;
    }
    if (!(Object.keys(templatesElements).includes(useTemplate))) {
      continue;
    }

    configFilled.design.elements[key] = mergeElement(element, templatesElements[useTemplate]);
  }



  return configFilled;
}

export const fetchValueAlternative = (path: string, obj1?: AppConfig, obj2?: AppConfig) => {
  if (!obj1) {
    return undefined;
  }
  let [val, _] = getKeyVal(path, obj1);
  if (!val || val === "") {
    if (!obj2) {
      return undefined;
    }
    [val, _] = getKeyVal(path, obj2);
  }

  if (!val) {
    val = undefined;
  }
  return val;
}

export const fetchBooleanAlternative = (path: string, obj1?: AppConfig, obj2?: AppConfig, defaultValue = "true") => {
  const val = fetchValueAlternative(`design.orientation.lock`, obj1, obj2);
    if ([undefined, null].includes(val)) {
    return defaultValue;
  }
  return val.toString();
}


export {}
