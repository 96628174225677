import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.scss";
import { getCookie } from "../methods/cookie";
import {Background} from "../components/background/Background";
import {logout} from "../methods/helpers";
import {LoadingOverlay} from "../components/loadingOverlay/loading";

export const Auth = (props: any) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [usergroup, setUserGroup] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const login = async() => {
    if (username === '' || password === '') {
      return;
    }
    const params = new URLSearchParams();

    // params.append("grant_type", "password");
    params.append("username", username);
    params.append("password", password);
    // params.append("client_id", "jeff-client");
    // params.append("client_secret", "jeff-client");
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_URL}/auth/login`,{
      mode: 'cors',
      method: "post",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Accept': "application/json; charset=UTF-8",
      },
      body: params
    });
    setLoading(false);
    const r = await res.json();
    if (res.status === 401) {
      window.alert(r['detail'])
      return;
    }
    if (res.status !== 200) {
      window.alert(r['detail'])
      return;
    }
    const token = r.access_token;

    const now = new Date();
    const time = now.getTime();
    const expireTime = time + r.expires;
    now.setTime(expireTime);
    document.cookie = `jwt=${token};expires=${now.toUTCString()};path=/`;
    window.location.reload();
  }

  const createUser = async() => {
    const params = {
      username: username,
      password: password,
      email: email,
      fullname: fullname,
      usergroup: usergroup ?? ""
    }

    if (username === '' || password === '' || email === '' || fullname === '') {
      return;
    }
    // params.append("client_id", "jeff-client");
    // params.append("client_secret", "jeff-client");
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_URL}/auth/createUser`,{
      method: "post",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': "application/json; charset=UTF-8",
      },
      body: JSON.stringify(params)
    });
    setLoading(false);
    if (res.status === 401) {
      logout();
      return;
    }
    const r = await res.json();
    if (res.status !== 200) {
      window.alert(r['detail'])
      return;
    }
    if (res.status !== 200) {
      return;
    }
    window.alert("User created successfully.");
  }

  const checkKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      login();
      return;
    }
  }

  useEffect(() => {
    const jwt = getCookie('jwt');
    if (jwt !== '') {
      navigate('/home');
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['screen']}>
      <Background/>
      <div className={styles['form']}>
        <div className={styles['inputs']}>
          <div className={styles['input-row']}>
            <input
              className={styles['login-username']}
              value={username}
              placeholder={'Username...'}
              onKeyDown={(e) => {checkKey(e)}}
              onChange={(e) => {setUsername(e.target.value)}}
            />
            <input
              className={styles['login-password']}
              type="password"
              value={password}
              placeholder={'Password...'}
              onKeyDown={(e) => {checkKey(e)}}
              onChange={(e) => {setPassword(e.target.value)}}
            />
            <button className={styles['button']} onClick={login}>
              LOGIN
            </button>
          </div>
          <div className={styles['input-row']}>
            <input
              className={styles['login-username']}
              value={email}
              placeholder={'Email...'}
              // onKeyDown={(e) => {checkKey(e)}}
              onChange={(e) => {setEmail(e.target.value)}}
            />
            <input
              className={styles['login-password']}
              value={fullname}
              placeholder={'Full name...'}
              // onKeyDown={(e) => {checkKey(e)}}
              onChange={(e) => {setFullname(e.target.value)}}
            />
            <button className={styles['button']} onClick={createUser}>
              CREATE USER
            </button>
          </div>
          <div className={styles['input-row']}>
            <input
              className={styles['login-usergroup']}
              value={usergroup}
              placeholder={'Usergroup?'}
              // onKeyDown={(e) => {checkKey(e)}}
              onChange={(e) => {setUserGroup(e.target.value)}}
            />
          </div>
        </div>
      </div>
      <LoadingOverlay loading={loading}/>
    </div>
  )
}
