import { useNavigate } from "react-router-dom";
import { getCookie } from "../methods/cookie";
import { useEffect } from "react";
import styles from "./home.module.scss";
import {Background} from "../components/background/Background";
import {LanguageButton} from "../components/LanguageButton/LanguageButton";
import {logout, openTab} from "../methods/helpers";


export const Home = (props: any) => {
  const navigate = useNavigate();
  const jwt = getCookie('jwt');

  const logoutFunc = () => {
    document.cookie = `jwt=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`
    navigate('/auth');
  }

  useEffect(() => {
    if (jwt === '') {
      logout()
      // navigate('/auth');
      return;
    }
  })

  return <div className={styles['home-wrapper']}>
    <Background/>
    <div className={styles['home-content']}>
      <div className={styles['home-links']}>
        <h2>
          Select a design for a scanner.
        </h2>
        <p>
          This site will display a list of all scanners you have access to.
          <br></br>
          For each scanner, a dropdown list is offered, allowing you to select which of your designs the given scanner should use.
        </p>
        <input type="button" className={styles['home-link']} value={"Select design"} onMouseDown={(e) => {openTab(e, '/selectDesign')}}>
        </input>

        <h2>
          Assign a scanner to a user.
        </h2>
        <p>
          This site will display a list of all scanners you have access to.
          <br></br>
          For each scanner, you will be able to assign it to one or multiple new users, revoke their access, and set their privilege levels.
        </p>
        <input type="button" className={styles['home-link']} value={"Assign scanner"} onMouseDown={(e) => {openTab(e, '/assignScanner')}}>
        </input>


        <h2>
          Assign a design to a user.
        </h2>
        <p>
          This site will display a list of all designs you have access to.
          <br></br>
          For each design, you will be able to assign it to one or multiple new users, revoke their access, and set their privilege levels.
        </p>
        <input type="button" className={styles['home-link']} value={"Assign design"} onMouseDown={(e) => {openTab(e, '/assignDesign')}}>
        </input>

        <h2>
          Create or edit configs.
        </h2>
        <p>
          This site will allow for the creation of new configs. It is also possible to edit pre-existing ones.
        </p>
        <input type="button" className={styles['home-link']} value={"Generate config"} onMouseDown={(e) => {openTab(e, '/generateConfig')}}>
        </input>

        <h2>
          Manage your media.
        </h2>
        <p>
          This site grants access to your media, allowing you to upload new, and deleting existing ones.
        </p>
        <input type="button" className={styles['home-link']} value={"Media"} onMouseDown={(e) => {openTab(e, '/upload')}}>
        </input>

        <h2>
          Admin-only area.
        </h2>
        <p>
          This site allows administrators to create new scanners.
        </p>
        <input type="button" className={styles['home-link']} value={"Admin area"} onMouseDown={(e) => {openTab(e, '/admin')}}>
        </input>

        <h2>
          Log out.
        </h2>
        <input type="button" className={styles['home-link']} value={"Log out"} onClick={logoutFunc}>
        </input>
        <LanguageButton/>
      </div>
    </div>
  </div>
}
