import React, {ChangeEvent, useEffect, useState} from "react";
import  styles from "./index.module.scss";
import stylesGeneric from "../../styles/generics.module.scss";
import { getCookie } from "../../methods/cookie";
import { useNavigate } from "react-router-dom";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {deleteDesign, downloadFile, fetchDesign, fetchWrapper, postDesign} from "../../methods/connector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPlus} from "@fortawesome/free-solid-svg-icons";
import {AppConfigs} from "../../interfaces/AppConfig";
import {Background} from "../../components/background/Background";
import {logout, openTab} from "../../methods/helpers";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";


export const Index = () => {
    const navigate = useNavigate();
    const jwt = getCookie('jwt');
    const [designs, setDesigns] = useState<string[]>([]);
    const [searchStringDesign, setSearchStringDesign] = useState<string>("");
    const [add, setAdd] = useState<boolean>(false);
    const [addString, setAddString] = useState<string>('');
    const [deleteTag, setDeleteTag] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const openDesign = (design: string) => {
        // setCurrentDesign(design);
        navigate(design)
    }

    const getDesigns = async() => {
        let url = `${process.env.REACT_APP_URL}/designList?name_only=true`;
        setLoading(true);
        const data = await fetchWrapper(url, jwt, 'get');
        setLoading(false);
        if (data.status === 401) {
            logout();
            return;
        }
        if (data.status !== 200) {
            return;
        }
        const r: AppConfigs[] = await data.json();
        setDesigns(r.map(el => el.designTag).sort());
    }

    useEffect(() => {

        // this is toggled twice, as the site is rendered twice
        // this is due to the strict mode being enabled (index.tsx)
        // this causes double-renders for dev-mode only
        if (jwt === '') {
            navigate('/auth');
            return;
        }
        // getData();
        getDesigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addEntry = async () => {
        setLoading(true);
        await postDesign({designTag: addString, configs: {}, settings: {initialStage: "home", homeStage: "home", numMeasurementsAox: 4}, timestamp: 0}, jwt, true);
        setLoading(false);
        getDesigns();
        setAddString('');
        setAdd(false);
    }

    const deleteEntry = async (designName: string) => {
        setDeleteTag('');
        setLoading(true);
        await deleteDesign(designName, jwt)
        setLoading(false);
        getDesigns();
        setAddString('');
        setAdd(false);
    }

    const downloadDesign = async (designName: string) => {
        const r = await fetchDesign(designName, jwt);
        if (!r) {
            return;
        }
        downloadFile(r, designName);
    }

    const uploadDesign = async (e: ChangeEvent<HTMLInputElement>, designName: string) => {
        if (!(e.target.files)){
            window.alert(`Could not read file.`);
            return;
        }
        setLoading(true);
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = async el => {
            if (!el?.target?.result || el.target.result instanceof ArrayBuffer) {
                window.alert(`Could not read file.`);
                setLoading(false);
                return;
            }
            try {
                const data: AppConfigs = JSON.parse(el.target.result);
                data.designTag = designName;
                const d = await postDesign(data, jwt);
                if (d.status !== 200) {
                    window.alert(`Could not import file.`);
                } else {
                    window.alert(`Successfully loaded config for ${designName}.`);
                }
                setLoading(false);
            } catch (e) {
                window.alert(`Could not import file.`);
                setLoading(false);
                return;
            }
        };
    }

    return (
        <div className={stylesGeneric["page"]}>
            <Background/>
            <div className={stylesGeneric["settings-button-row"]}>
                <div className={stylesGeneric["settings-button"]}>
                    <HomeButton/>
                </div>
                <div className={stylesGeneric["settings-button"]}>
                    <button className={stylesGeneric['generic-button-wrapper']} onClick={() => {setAdd(!add);}}>
                        <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faPlus} />
                    </button>
                </div>
            </div>
            <div className={stylesGeneric["content-wrapper"]}>
                <div className={stylesGeneric["page-wrapper"]}>
                    <table className={styles["table"]}>
                        <thead>
                        <tr>
                            <td className={styles["table-cell-wide"]}>
                                <input
                                    className={styles["search-bar"]}
                                    value={searchStringDesign}
                                    placeholder="Design..."
                                    onChange={(el) => setSearchStringDesign(el.currentTarget.value)}
                                >
                                </input>
                            </td>
                            <td className={styles["table-cell"]}/>
                            <td className={styles["table-cell"]}/>
                            <td className={styles["table-cell"]}/>
                            <td className={styles["table-cell"]}/>

                        </tr>
                        </thead>
                        <tbody>
                        {
                            designs.filter(e => e.includes(searchStringDesign)).map(el => {
                                return(<tr className={stylesGeneric["table-row"]} key={el}>
                                    <td className={styles["table-cell-wide"]}>
                                        {el}
                                    </td>
                                    <td className={styles["table-cell"]}>
                                        <input
                                            type="button"
                                            className={stylesGeneric["dropdown-wrapper"]}
                                            onMouseDown={(e) => {e.stopPropagation(); openTab(e, `generateConfig/${el}`)}}
                                            value="Edit"
                                        />
                                    </td>
                                    <td className={styles["table-cell"]}>
                                        <input
                                          type="button"
                                          className={stylesGeneric["dropdown-wrapper"]}
                                          onClick={(e) => {e.stopPropagation(); downloadDesign(el)}}
                                          value="Export"
                                        />
                                    </td>
                                    <td className={styles["table-cell"]}>
                                        <input
                                          type="file"
                                          className={styles["import-hide"]}
                                          id={`file-upload-${el}`}
                                          onChange={(e) => {uploadDesign(e, el)}}
                                        />
                                        <button
                                            className={stylesGeneric["dropdown-wrapper"]}
                                            value="Import"
                                        >
                                            <label className={styles["import-button"]} htmlFor={`file-upload-${el}`}>
                                                Import
                                            </label>
                                        </button>
                                    </td>
                                    <td className={styles["table-cell"]}>
                                        <input
                                          type="button"
                                          className={stylesGeneric["dropdown-wrapper"]}
                                          onClick={(e) => {e.stopPropagation(); setDeleteTag(el)}}
                                          value="Delete"
                                        />
                                    </td>
                                </tr>)
                            })
                        }
                        </tbody>
                    </table>
                </div>
                {
                    add
                      ?
                      <div className={styles["add-wrapper"]}>
                          <input value={addString} placeholder={'Enter new config name...'} onChange={(e) => {setAddString(e.target.value)}} className={styles["add-input"]}/>
                          <div className={styles["add-buttons"]}>
                              <input className={styles["add-button"]} value={"OK"} onClick={addEntry} type="button"/>
                              <input className={styles["add-button"]} value={"Cancel"} onClick={() => {setAdd(false); setAddString('')}} type="button"/>
                          </div>
                      </div>
                      : null
                }
                {
                    deleteTag !== ''
                      ?
                      <div className={styles["add-wrapper"]}>
                          <p>
                              Delete {deleteTag}?
                          </p>
                          <div className={styles["add-buttons"]}>
                              <input className={styles["add-button"]} value={"OK"} onClick={(e) => {e.stopPropagation(); deleteEntry(deleteTag)}} type="button"/>
                              <input className={styles["add-button"]} value={"Cancel"} onClick={() => {setDeleteTag(''); }} type="button"/>
                          </div>
                      </div>
                      : null
                }
            </div>
            <LoadingOverlay loading={loading}/>
        </div>
    )
}
