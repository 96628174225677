import {AppConfig} from "../interfaces/AppConfig";
import {
  getAdditionalKeys,
  getElementTypes,
  getEventList,
  getSiteFunctionList,
  getMandatoryKeys, getButtonFunctionList, getFunctionTypes
} from "./getEvents";
import {retrieveKey} from "./helpers";
import {extractURLs, fetchURLs} from "./renderUtils";

const _ = require('lodash');


export const validateURLs = async (design: AppConfig) => {
  // const urls = parseURLs(design);
  const urls = await extractURLs(design);
  const missingNames: string[] = await fetchURLs(urls);
  return missingNames;
}

export const validateDesign = (
  design: AppConfig,
  pageNames: string[]
): {status: boolean, errorMsg?: string} => {

  // validate stages
  const requiredStages = getEventList(design.settings.callFunction ?? "");
  const targets: Record<string, string> = {};
  if (!(design.design.orientation?.lock)) {
    design.design.orientation = {
      lock: true,
      value: 'portrait'
    }
  } else if (!(design.design.orientation.value) && design.design.orientation.lock) {
    design.design.orientation.value = 'portrait';
  }
  Object.entries(design.settings.eventTargets ?? {}).filter(([key, val]) => {return requiredStages.includes(key)}).forEach(([key, val]) => {targets[key] = val});
  design.settings.eventTargets = targets;
  for (const [stage, stageTarget] of Object.entries(design.settings.eventTargets ?? {})) {
    if (!(pageNames.includes(stageTarget))) {
      return {
        status: false,
        errorMsg: `Invalid stage target for stage ${stage}`
      }
    }
  }
  const stageMissing = requiredStages.filter((stage) => {return !(Object.keys(design.settings.eventTargets ?? {}).includes(stage))}).length > 0;
  if (stageMissing) {
    return {
      status: false,
      errorMsg: `Missing stage target`
    }
  }

  const mandatoryKeys = getMandatoryKeys();
  const elementTypes = getElementTypes();
  const functions = getSiteFunctionList();
  const elementFunctions = getButtonFunctionList();

  let element: Record<string, any>;
  let key: string;
  for ([key, element] of Object.entries(design.design.elements ?? {})) {


    const elementType = element.elementType;
    if (!elementType || !(elementTypes.includes(elementType))) {
      return {
        status: false,
        errorMsg: `Unsupported element type: ${elementType}`
      }
    }

    const validKeys = getAdditionalKeys(elementType).map((e) => e.key);
    for (const k of ['goTo', 'condition', 'background', 'handleFunction', 'design', 'url', 'resource', 'index']) {
      if (validKeys.filter((l) => l.startsWith(k)).length === 0) {
        delete element[k];
      }
    }

    for (const mandatoryKey of mandatoryKeys) {
      if (!element.css?.[mandatoryKey]) {
        return {
          status: false,
          errorMsg: `Missing css value ${mandatoryKey} in ${key}`
        }
      }
    }
    const additionalKeys = getAdditionalKeys(elementType)
    for (const additionalKey of additionalKeys) {
      if ((retrieveKey(element, additionalKey.key) === undefined)  && additionalKey.mandatory) {
        return {
          status: false,
          errorMsg: `Missing value ${additionalKey.key} in ${key}`
        }
      }
      if (additionalKey.key === 'goTo' && additionalKey.mandatory) {
        if (!(pageNames.includes(element[additionalKey.key] ?? ""))){
          return {
            status: false,
            errorMsg: `Missing goto target for element ${key}`
          }
        }
      }
    }

    if (element.handleFunction !== undefined && element.handleFunction !== null) {
      if (element.handleFunction?.name && element.handleFunction.name !== '') {
        if (!(elementFunctions.includes(element.handleFunction.name))) {
          return {
            status: false,
            errorMsg: `Invalid value: handleFunction.name for element: ${key}`
          }
        }
      }
      if (element.handleFunction?.isRadio &&
        (!element.handleFunction?.buttonGroup || element.handleFunction?.buttonGroup === '')
      ) {
        return {
          status: false,
          errorMsg: `Missing button group for element: ${key}`
        }

      }
    }
  }

  if (design.settings.timeout?.timer && design.settings.timeout?.timer > 0) {
    if (design.settings.timeout.onExpiration === undefined || !(pageNames.includes((design.settings.timeout.onExpiration)))) {
      return {
        status: false,
        errorMsg: `Missing value: onExpiration`
      }
    }
  }

  if (design.settings.callFunction){
    if (!(functions.includes(design.settings.callFunction))){
      return {
        status: false,
        errorMsg: `Invalid value: callFunction`
      }
    }
    if (design.settings.callFunction === "if") {
      const params = design.settings.functionParameters;
      if (!params) {
        return {
          status: false,
          errorMsg: `No function parameters provided`
        }
      }
      if (
        !params || params.key === "" ||
        params?.value === undefined || params.value === "" ||
        !params?.group || params.group === ""
      ) {
        return {
          status: false,
          errorMsg: `No function parameters provided`
        }
      }
    }

    if (design.settings.copyParameters) {
      design.settings.copyParameters = design.settings.copyParameters.filter((el) => {
        return (el.originGroup && el.originKey && el.targetGroup && el.targetKey)
      })
    }

    if (design.settings.setParameters) {
      design.settings.setParameters = design.settings.setParameters.filter((el) => {
        if (el?.newValueType === 'function' && getFunctionTypes()[1].includes(el?.newValue?.toString() ?? "")) {
          return (el.newValue && el.newValueType && el.targetGroup && el.targetKey
            && el.inputKey1 && el.inputGroup1 && el.inputKey2 && el.inputGroup2
          )
        } else if (el?.newValueType === 'function' && getFunctionTypes()[2].includes(el?.newValue?.toString() ?? "")) {
          el.min = el.min ?? 0;
          return (el.newValue && el.newValueType && el.targetGroup && el.targetKey
            && el.max !== undefined && el.max > el.min
          )
        } else {
          if (el.newValueType === 'string' && !el.newValue) {
            el.newValue = '';
          }
          return (
            el.newValue !== undefined
            && el.newValueType !== undefined
            && el.targetGroup !== undefined
            && el.targetKey !== undefined
          )
        }
      });
      design.settings.setParameters.forEach((params) => {
        switch (params.newValueType) {
          case ('boolean'): {
            params.newValue = params.newValue === 'true' || params.newValue === true
            break;
          }
          case ('number'): {
            params.newValue = Number(params.newValue);
          }
        }
      })
    }
  }


  if (design.design.backgroundSize?.width || design.design.backgroundSize?.height) {
    if (!design.design.backgroundSize.width || !design.design.backgroundSize.height) {
      return {
        status: false,
        errorMsg: `Missing size values`
      }
    }
    if (design.design.backgroundSize?.hasBorder) {
      if (
        (!design.design.backgroundSize?.sectionWidth) ||
        (!design.design.backgroundSize?.sectionHeight) ||
        (!design.design.backgroundSize?.sectionX && design.design.backgroundSize?.sectionX !== 0) ||
        (!design.design.backgroundSize?.sectionY && design.design.backgroundSize?.sectionY !== 0)) {
        return {
          status: false,
          errorMsg: `Missing size values`
        }
      }
    }
  }

  const backgroundBounds = design.design.backgroundBounds;
  if (
    backgroundBounds?.minWidth
    || backgroundBounds?.minHeight
    || backgroundBounds?.maxWidth
    || backgroundBounds?.maxHeight
    || backgroundBounds?.goTo
  ) {
    if (
      !backgroundBounds.minWidth
      || !backgroundBounds?.minHeight
      || !backgroundBounds?.maxWidth
      || !backgroundBounds?.maxHeight
      || !backgroundBounds.goTo
    ) {
      return {
        status: false,
        errorMsg: `Missing boundary values`
      }
    }
  }

  return {status: true};
}
